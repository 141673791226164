<template>
    <div class="dicomCloud">
        <div class="topImage">
            <div class="topInfo">
                <div class="topTitle">指云端智慧医疗服务平台</div>
                <div class="topDesc">为患者、医院、医生提供高效、安全、专业的影像服务</div>
            </div>
        </div>
        <div class="links">
            <router-link class="productLink" to="/web/product/couldDicom" active-class="linkActive">
                <div class="linkContentArea">
                    <i class="linkIcon iconfont  icon-yuncunchu"></i>云影像
                </div>
            </router-link>
            <router-link class="productLink" to="/web/product/dicomCloudStorage" active-class="linkActive">
                <div class="linkContentArea">
                    <i class="linkIcon iconfont  icon-yingxiangtiaoyue"></i>影像云存储
                </div>
            </router-link>
            <router-link class="productLink" to="/web/product/regionalCloudDicom" active-class="linkActive">
                <div class="linkContentArea">
                    <i class="linkIcon iconfont  icon-xietong"></i>医疗协同
                </div>
            </router-link>
        </div>
        <router-view></router-view>
        <smallfoot></smallfoot>

    </div>
</template>

<style lang="less" scoped>
.dicomCloud{
    border: none;
    .topImage{
        overflow: hidden;
    height: 476px;
    width: 100%;
    background: url('../../../assets/images/web/product/bejjing@2x.png') no-repeat;
    background-size: cover;
    .topInfo{
        width: 1200px;
        margin:  0 auto;
        height: 100%;
        .topTitle{
            font-size: 55px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            margin-top: 138px;
        }
        .topDesc{
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 63px;
        }
    }
}

.links{
    display: flex;
    flex-direction: row;
    width: 661px;
    height: 65px;
    background: #F5F5F5;
    margin: 0 auto;
    margin-top: 60px;
    .productLink{
        &:last-child{
            .linkContentArea{
                border: none;
            }
        }
        width: 221px;
        height: 65px;
        .linkContentArea{
            width: 100%;
            margin-top: 20px;
            height: 31px;
            color: #000000;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: solid 1px #999999;
            .linkIcon{
                color: #ADADAD;
                font-size: 30px;
                margin-right: 15px;
            }
        }
    }
    .linkActive{
        background: #0C2948;
        color: white;
        .linkContentArea{
            border: none;
            color: white;
            .linkIcon{
                background: #0C2948;
                color: white;
            }
        }
    }
}
}

</style>

<script>
import smallfoot from '../../../components/webFooter/index.vue'
export default {
    components: { smallfoot },
    data() {
        return {
        }
    }
}
</script>
